import { useCallback, useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  ISidebarTogglingData,
  SidebarTogglingAction,
  ISidebarEmissionDataBase,
  IUseAutomaticTogglingParams,
} from '../types';

import { forgetSidebarRememberedToBeOpenedAutomatically$, anySidebarRecentTogglingData$ } from './constants';

export const useAutomaticToggling = (params: IUseAutomaticTogglingParams) => {
  const { side, isSidebarOpen, openSidebar, closeSidebar }: IUseAutomaticTogglingParams = params;
  const sidebarIdentifier = useRef();
  const isSidebarRememberedToBeOpenedAutomaticallyRef = useRef<boolean>(false);

  const shouldHandleSidebarEmission = useCallback(
    <T extends ISidebarEmissionDataBase>(data: T): boolean => {
      return side === data.side && sidebarIdentifier !== data.sidebarIdentifier;
    },
    [side]
  );

  const handleAnotherSidebarToggling = useCallback(
    (data: ISidebarTogglingData) => {
      switch (data.recentAction) {
        case SidebarTogglingAction.Opened: {
          if (isSidebarOpen) {
            closeSidebar();

            forgetSidebarRememberedToBeOpenedAutomatically$.next({ sidebarIdentifier, side });

            isSidebarRememberedToBeOpenedAutomaticallyRef.current = true;
          }

          break;
        }

        case SidebarTogglingAction.Closed: {
          if (isSidebarRememberedToBeOpenedAutomaticallyRef.current) {
            openSidebar();

            isSidebarRememberedToBeOpenedAutomaticallyRef.current = false;
          }
        }
      }
    },
    [side, isSidebarOpen, openSidebar, closeSidebar]
  );

  const handleSidebarRememberedToBeOpenedAutomaticallyForgetting = () => {
    isSidebarRememberedToBeOpenedAutomaticallyRef.current = false;
  };

  useEffect(() => {
    const sidebarTogglingDataSubscription: Subscription = anySidebarRecentTogglingData$
      .pipe<ISidebarTogglingData>(filter(shouldHandleSidebarEmission))
      .subscribe(handleAnotherSidebarToggling);

    return () => {
      sidebarTogglingDataSubscription.unsubscribe();
    };
  }, [handleAnotherSidebarToggling, shouldHandleSidebarEmission]);

  useEffect(() => {
    const forgetSidebarRememberedToBeOpenedAutomaticallySubscription: Subscription =
      forgetSidebarRememberedToBeOpenedAutomatically$
        .pipe<ISidebarEmissionDataBase>(filter(shouldHandleSidebarEmission))
        .subscribe(handleSidebarRememberedToBeOpenedAutomaticallyForgetting);

    return () => {
      forgetSidebarRememberedToBeOpenedAutomaticallySubscription.unsubscribe();
    };
  }, [side, shouldHandleSidebarEmission]);

  useEffect(() => {
    anySidebarRecentTogglingData$.next({
      sidebarIdentifier,
      side,
      recentAction: isSidebarOpen ? SidebarTogglingAction.Opened : SidebarTogglingAction.Closed,
    });
  }, [isSidebarOpen, side]);
};
